export enum QuestionType {
    open = "free_answer",
    singleChoice =  "answer_options"
}

export interface Answer {
    id: string;
    text: string;
}

export interface Question {
    id: string;
    text: string;
    type: QuestionType;
    answers: Answer[]
}

export interface Game {
    position: number;
    questions: Question[];
}