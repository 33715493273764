// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_container__CM25V {
  height: 48px;
  width: 100%;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: linear-gradient(15.97deg, #e98b10 10.65%, #ffb932 88.4%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.button_wrapper__2ixy0 {
  display: flex;
  align-items: center;
  gap: 12px;

  font-size: 16px;
  font-weight: 700;
  line-height: 19.09px;
  letter-spacing: -0.0143em;
  text-align: center;
  color: white;
}

.button_spinner__-p-XR {
  animation: button_spin__ZWQPg 4s linear infinite;
}
@keyframes button_spin__ZWQPg {
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/Button/button.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,oEAAoE;EACpE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;;EAET,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EAGE,gDAAkC;AACpC;AAYA;EACE;IAEE,yBAAyB;EAC3B;AACF","sourcesContent":[".container {\n  height: 48px;\n  width: 100%;\n  border-radius: 10px;\n  border: none;\n  cursor: pointer;\n  background: linear-gradient(15.97deg, #e98b10 10.65%, #ffb932 88.4%);\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  gap: 12px;\n\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 19.09px;\n  letter-spacing: -0.0143em;\n  text-align: center;\n  color: white;\n}\n\n.spinner {\n  -webkit-animation: spin 4s linear infinite;\n  -moz-animation: spin 4s linear infinite;\n  animation: spin 4s linear infinite;\n}\n\n@-moz-keyframes spin {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `button_container__CM25V`,
	"wrapper": `button_wrapper__2ixy0`,
	"spinner": `button_spinner__-p-XR`,
	"spin": `button_spin__ZWQPg`
};
export default ___CSS_LOADER_EXPORT___;
