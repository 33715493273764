import React, { FC } from 'react';
import styles from './button.module.css';

interface Props {
  title: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
}

const Button = ({
  title,
  isDisabled,
  isLoading,
  onClick,
}: Props) => {

  return (
    <button className={styles.container} onClick={() => !isLoading && onClick()}>
        <div className={styles.wrapper}>
          { isLoading && <img className={styles.spinner} src='/images/circular-indeterminate.svg' width={20} height={20} />}
          {title}
        </div>
    </button>
  );
};

export default Button;