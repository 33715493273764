import { useState } from "react";
import { isObject } from "lodash";

export const useLocalStorage = (keyName: string, defaultValue?: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    const value = localStorage.getItem(keyName);

    if (value) {
      try {
        return JSON.parse(value);
      } catch (err) {
        return value;
      }
    } else {
      return defaultValue;
    }
  });

  const setValue = (newValue: any) => {
    if (newValue) {
      localStorage.setItem(
        keyName,
        isObject(newValue) ? JSON.stringify(newValue) : newValue
      );
    } else {
      localStorage.removeItem(keyName);
    }
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};
