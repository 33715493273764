// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash_wrapper__FyCL5 {
  padding: 40px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
}

.splash_title__urD6I {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.0143em;
  text-align: center;
  color: white;
}

.splash_description__f0t2c {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: center;
  color: white;
  margin-top: 8px;
}

.splash_spacer__uDolI {
  flex: 1 1;
}

.splash_icon__fvZaf {
  margin-top: 24px;
}

.splash_image__6T0Qz {
  margin-top: 28px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/components/Splash/splash.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".wrapper {\n  padding: 40px 36px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100dvh;\n}\n\n.title {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 40px;\n  letter-spacing: -0.0143em;\n  text-align: center;\n  color: white;\n}\n\n.description {\n  font-size: 13px;\n  font-weight: 700;\n  line-height: 17px;\n  letter-spacing: 0.01em;\n  text-align: center;\n  color: white;\n  margin-top: 8px;\n}\n\n.spacer {\n  flex: 1;\n}\n\n.icon {\n  margin-top: 24px;\n}\n\n.image {\n  margin-top: 28px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `splash_wrapper__FyCL5`,
	"title": `splash_title__urD6I`,
	"description": `splash_description__f0t2c`,
	"spacer": `splash_spacer__uDolI`,
	"icon": `splash_icon__fvZaf`,
	"image": `splash_image__6T0Qz`
};
export default ___CSS_LOADER_EXPORT___;
