// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/SF-Pro-Text-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/SF-Pro-Text-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/SF-Pro-Text-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/SF-Pro-Text-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "SFPro";
  src: local("Lato"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}

@font-face {
  font-family: "SFPro";
  font-weight: 200;
  src: local("Lato"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("opentype");
}

@font-face {
  font-family: "SFPro";
  font-weight: 500;
  src: local("Lato"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("opentype");
}

@font-face {
  font-family: "SFPro";
  font-weight: 600;
  src: local("Lato"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("opentype");
}

.Toastify__toast-container {
  padding: 0 16px !important;
  top: 20px !important;
}

.Toastify__toast {
  background: #ff4545 !important;
  border-radius: 10px !important;
}

.Toastify__toast-body > div {
  color: white !important;
  font-weight: 600 !important;
  font-size: 15px;
  line-height: 1.4;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB;8DACgE;AAClE;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB;8DAC8D;AAChE;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB;8DAC+D;AACjE;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB;8DAC6D;AAC/D;;AAEA;EACE,0BAA0B;EAC1B,oBAAoB;AACtB;;AAEA;EACE,8BAA8B;EAC9B,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: \"SFPro\";\n  src: local(\"Lato\"),\n    url(./assets/fonts/SF-Pro-Text-Regular.ttf) format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"SFPro\";\n  font-weight: 200;\n  src: local(\"Lato\"),\n    url(./assets/fonts/SF-Pro-Text-Light.ttf) format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"SFPro\";\n  font-weight: 500;\n  src: local(\"Lato\"),\n    url(./assets/fonts/SF-Pro-Text-Medium.ttf) format(\"opentype\");\n}\n\n@font-face {\n  font-family: \"SFPro\";\n  font-weight: 600;\n  src: local(\"Lato\"),\n    url(./assets/fonts/SF-Pro-Text-Bold.ttf) format(\"opentype\");\n}\n\n.Toastify__toast-container {\n  padding: 0 16px !important;\n  top: 20px !important;\n}\n\n.Toastify__toast {\n  background: #ff4545 !important;\n  border-radius: 10px !important;\n}\n\n.Toastify__toast-body > div {\n  color: white !important;\n  font-weight: 600 !important;\n  font-size: 15px;\n  line-height: 1.4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
