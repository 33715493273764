import { GameResults } from "../../app/models/game-result.entity";
import { GameSession } from "../../app/models/game-session.entity";
import { Game } from "../../app/models/game.entity";
import { UserSession } from "../../app/models/user-session.entity";
import { IntegrationDetail } from "../../app/models/topic-detail.entity";

export class ApiClient {
  baseUrl: string = "https://api.dailycup.ru/v1/web";

  getHeaders = (token?: string) => {
    return {
      Accept: "application/json",
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    };
  };

  async getCurrentSession(token: string): Promise<GameSession> {
    const result = await fetch(`${this.baseUrl}/sessions/me`, {
      headers: this.getHeaders(token),
    });

    if (!result.ok) {
      throw new Error("Something went wrong");
    }

    return result.json();
  }

  async createSession(integrationId: string): Promise<UserSession> {
    const result = await fetch(`${this.baseUrl}/sessions/new`, {
      headers: this.getHeaders(),
      method: "POST",
      body: JSON.stringify({ integration_id: integrationId }),
    });

    if (!result.ok) {
      throw new Error("Something went wrong");
    }

    return result.json();
  }

  async getResults(token: string, sessionId: string): Promise<GameResults> {
    const result = await fetch(`${this.baseUrl}/sessions/${sessionId}/result`, {
      headers: this.getHeaders(token),
    });

    if (!result.ok) {
      throw new Error("Something went wrong");
    }

    return result.json();
  }

  async getIntegrationDetail(token: string, integrationId: string): Promise<IntegrationDetail> {
    const result = await fetch(`${this.baseUrl}/integrations/${integrationId}`, {
      headers: this.getHeaders(token),
    });

    if (!result.ok) {
      throw new Error("Something went wrong");
    }

    return result.json();
  }

  async getGame(token: string, integrationId: string): Promise<Game> {
    const result = await fetch(`${this.baseUrl}/questions?integrationId=${integrationId}`, {
      headers: this.getHeaders(token),
    });

    if (!result.ok) {
      throw new Error("Something went wrong");
    }

    return result.json();
  }

  async postQuestionAnswer(
    token: string,
    questionId: string,
    answerId: string | undefined,
    answer: string | undefined
  ): Promise<void> {
    const result = await fetch(`${this.baseUrl}/answers`, {
      headers: this.getHeaders(token),
      method: "POST",
      body: JSON.stringify({
        question_id: questionId,
        answer_id: answerId,
        free_answer: answer,
      }),
    });

    if (!result.ok) {
      throw new Error("Something went wrong");
    }
  }
}
