import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiClient } from "../../../providers/api-client";
import { IntegrationDetail } from "../../models/topic-detail.entity";
import {
  GameSession,
  GameSessionState,
} from "../../models/game-session.entity";
import Loading from "../components/Loading/Loading";
import Splash from "../components/Splash/Splash";
import Questions from "../components/Questions/questions";
import Results from "../components/Results/Results";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { UserSession } from "../../models/user-session.entity";
import { Game } from "../../models/game.entity";
import { toast } from "react-toastify";
import { ACCESS_TOKEN_PREF } from "../../utils/constants";

const GameScreen = () => {
  const client = useApiClient();

  const { integration } = useParams();
  const [token, setToken] = useLocalStorage(ACCESS_TOKEN_PREF, null);

  const [gameInfo, setGameInfo] = useState<IntegrationDetail>();
  const [gameSession, setGameSession] = useState<GameSession>();
  const [game, setGame] = useState<Game>();

  async function createNewSession(integration: string): Promise<UserSession> {
    const result = await client.createSession(integration);

    setToken(result.token);
    return result;
  }

  async function getSession(integration: string): Promise<UserSession> {
    if (token) {
      const session = await client.getCurrentSession(token);

      if (session.integration_id === integration) {
        return { session, token };
      } else {
        return createNewSession(integration);
      }
    } else {
      return createNewSession(integration);
    }
  }

  async function launch(integration: string) {
    const { session, token } = await getSession(integration);
    setGameSession(session);

    const topicDetails = await client.getIntegrationDetail(token, integration);
    setGameInfo(topicDetails);

    const game = await client.getGame(token, integration);
    setGame(game);
  }

  useEffect(() => {
    if (integration) {
      launch(integration)
        .then(() => {
          console.log("Game loaded");
        })
        .catch((error: any) => {
          setToken(null);
          console.log(error);

          toast.error("Не удалось выполнить запрос. Попробуйте еще раз.", {
            position: "top-center",
            hideProgressBar: true,
            icon: false,
            closeButton: false,
          });
        });
    }
  }, [integration]);

  if (token && gameSession && gameInfo) {
    if (gameSession.state === GameSessionState.finished) {
      return <Results token={token} integrationId={gameSession.id} />;
    }

    if (gameSession.state === GameSessionState.inProgress && game) {
      return (
        <Questions
          token={token}
          game={game}
          questionsNumber={gameInfo.questions_count}
          onGameFinished={() => {
            setGameSession({
              ...gameSession,
              state: GameSessionState.finished,
            });
          }}
        />
      );
    }

    if (gameSession.state === GameSessionState.initial) {
      return (
        <Splash
          gameInfo={gameInfo}
          onStarted={() => {
            setGameSession({
              ...gameSession,
              state: GameSessionState.inProgress,
            });
          }}
        />
      );
    }
  }

  return <Loading />;
};

export default GameScreen;
