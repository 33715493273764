// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFound_wrapper__FPjUz {
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notFound_title__eyjd1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.0143em;
  text-align: center;
  color: white;
}

.notFound_description__3BecZ {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.01em;
  text-align: center;
  color: white;
  margin-top: 40px;
}

.notFound_image__02rEb {
  margin-top: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/NotFoundScreen/notFound.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  padding: 40px 16px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.title {\n  font-size: 32px;\n  font-weight: 700;\n  line-height: 40px;\n  letter-spacing: -0.0143em;\n  text-align: center;\n  color: white;\n}\n\n.description {\n  font-size: 13px;\n  font-weight: 700;\n  line-height: 17px;\n  letter-spacing: 0.01em;\n  text-align: center;\n  color: white;\n  margin-top: 40px;\n}\n\n.image {\n  margin-top: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `notFound_wrapper__FPjUz`,
	"title": `notFound_title__eyjd1`,
	"description": `notFound_description__3BecZ`,
	"image": `notFound_image__02rEb`
};
export default ___CSS_LOADER_EXPORT___;
