export enum GameSessionState {
    initial = "initial",
    inProgress = "in_progress",
    finished = "finished"
}

export interface GameSession {
    id: string;
    state: GameSessionState,
    integration_id: string;
}