import React from "react";

import "./App.css";
import { Route, Routes } from "react-router-dom";
import GameScreen from "./app/modules/GameScreen/GameScreen";
import NotFoundScreen from "./app/modules/NotFoundScreen/NotFoundScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/integrations/:integration/" element={<GameScreen />} />

        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
