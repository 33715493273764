import styles from './notFound.module.css';

import { ReactComponent as Logo} from '../../../assets/images/404.svg';

const NotFoundScreen = () => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Что-то пошло не так</h3>
      <Logo className={styles.image} />
      <p className={styles.description}>К сожалению мы не можем показать вам содержимое данной страницы, попробуйте еще раз</p>

    </div>
  );
};

export default NotFoundScreen;