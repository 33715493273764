// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}

.golden-cup {
  position: relative;
  animation: shine 2s infinite;
}

.golden-cup svg {
  width: 100px;
  height: 100px;
  fill: #ffd700;
  stroke: #ffcc00;
}

@keyframes shine {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/components/Loading/Loading.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,eAAe;AACjB;;AAEA;EACE;IACE,mBAAmB;IACnB,UAAU;EACZ;EACA;IACE,qBAAqB;IACrB,YAAY;EACd;EACA;IACE,mBAAmB;IACnB,UAAU;EACZ;AACF","sourcesContent":[".splash-screen {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100dvh;\n}\n\n.golden-cup {\n  position: relative;\n  animation: shine 2s infinite;\n}\n\n.golden-cup svg {\n  width: 100px;\n  height: 100px;\n  fill: #ffd700;\n  stroke: #ffcc00;\n}\n\n@keyframes shine {\n  0% {\n    transform: scale(1);\n    opacity: 1;\n  }\n  50% {\n    transform: scale(1.1);\n    opacity: 0.8;\n  }\n  100% {\n    transform: scale(1);\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
