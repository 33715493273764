import React, { useEffect, useMemo, useState } from "react";
import styles from "./results.module.css";
import { QuestionResult } from "../../../models/game-result.entity";
import { useApiClient } from "../../../../providers/api-client";
import Button from "../../../components/Button/Button";
import cupLogo from "../../../../assets/images/cup-silver-gold-300.svg";
import ValidAnswer from "../../../../assets/images/valid-answer.png";
import InvalidAnswer from "../../../../assets/images/invalid-answer.png";
import { toast } from "react-toastify";

interface Props {
  token: string;
  integrationId: string;
}

const Results = ({ token, integrationId }: Props) => {
  const client = useApiClient();
  const [results, setResults] = useState<QuestionResult[]>();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    client
      .getResults(token, integrationId)
      .then((result) => {
        setResults(result.results);
      })
      .catch((error: any) => {
        console.log(error);

        toast.error("Не удалось выполнить запрос. Попробуйте еще раз.", {
          position: "top-center",
          hideProgressBar: true,
          icon: false,
          closeButton: false,
        });
      });
  }, []);

  const correctResults = useMemo(() => {
    return results?.filter((r) => r.answer.is_correct).length ?? 0;
  }, [results]);

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Результаты</h3>

      {!showDetails ? (
        <>
          <img
            className={styles.image}
            src={cupLogo}
            width={100}
            height={100}
            alt="лого"
          />
          <p className={styles.description}>Ваш результат</p>
          {results ? (
            <p className={styles.result}>
              <span style={{ color: "#01E44E" }}>{correctResults}</span> /{" "}
              {results.length}
            </p>
          ) : (
            <img
              className={styles.spinner}
              src="/images/circular-indeterminate-thin.svg"
              width={48}
              height={48}
              alt="загрузка"
            />
          )}

          <div className={styles.spacer} />

          {results && (
            <Button title="ДЕТАЛИ" onClick={() => setShowDetails(true)} />
          )}
        </>
      ) : (
        <div className={styles.answers}>
          {results?.map((result, position) => (
            <div className={styles.answer}>
              <span className={styles.answerPosition}>{position + 1}</span>
              <span className={styles.answerText}>{result.question.text}</span>

              <img
                className={styles.answerImage}
                src={result.answer.is_correct ? ValidAnswer : InvalidAnswer}
                height={66}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Results;
